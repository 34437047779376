exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-aggierewards-js": () => import("./../../../src/pages/projects/aggierewards.js" /* webpackChunkName: "component---src-pages-projects-aggierewards-js" */),
  "component---src-pages-projects-bioportal-site-js": () => import("./../../../src/pages/projects/bioportal-site.js" /* webpackChunkName: "component---src-pages-projects-bioportal-site-js" */),
  "component---src-pages-projects-discordbot-js": () => import("./../../../src/pages/projects/discordbot.js" /* webpackChunkName: "component---src-pages-projects-discordbot-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-rentalapp-js": () => import("./../../../src/pages/projects/rentalapp.js" /* webpackChunkName: "component---src-pages-projects-rentalapp-js" */),
  "component---src-pages-projects-test-js": () => import("./../../../src/pages/projects/test.js" /* webpackChunkName: "component---src-pages-projects-test-js" */),
  "component---src-pages-projects-vibeify-js": () => import("./../../../src/pages/projects/vibeify.js" /* webpackChunkName: "component---src-pages-projects-vibeify-js" */)
}

